import * as React from "react";
import { Helmet } from "react-helmet";

type Props = {
  title: string;
  description: string;
};

const Head: React.FC<Props> = (props) => {
  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <meta name="format-detection" content="telephone=no" />
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
    </Helmet>
  );
};

export default Head;
