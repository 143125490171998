import { Route, Routes } from "react-router-dom";
import "./App.css";
import InputEmail from "./pages/inputEmail";
import ComfirmEmail from "./pages/confirmEmail";
import Layout from "./components/Layout";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<InputEmail />} />
        <Route path="/comfirm" element={<ComfirmEmail />} />
        <Route path="*" element={<h2>Not Found</h2>} />
      </Route>
    </Routes>
  );
}

export default App;
