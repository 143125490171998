import { useState } from "react";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Head from "../components/Head";
import { TextField, Button } from "@mui/material";

const emailSchema = z.string().email();
const nullEmail = "メールアドレスを入力してください";
const errorEmail = "正しい形式のメールアドレスを入力してください";

const InputEmail = () => {
  const [email, setEmail] = useState<string>("");
  const [validationError, setValidationError] = useState<string | null>(
    nullEmail
  );

  const navigate = useNavigate();
  const moveLineConnect = () => navigate(`comfirm`, { state: email });

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    validateEmail(newEmail);
  };

  const validateEmail = (input: string) => {
    try {
      if (!input) {
        setValidationError(nullEmail);
        return;
      }
      emailSchema.parse(input);
      setValidationError(null);
    } catch (error) {
      setValidationError(errorEmail);
    }
  };

  return (
    <>
      <Head
        title="メールアドレス登録"
        description="メールアドレスを登録します"
      />

      <Container
        maxWidth="sm"
        style={{
          marginTop: "20px",
          border: "1px solid #ccc",
          padding: "20px",
          height: "280px",
        }}
      >
        <Box
          sx={{
            bgcolor: "#ffffff",
            height: "150px",
            marginTop: "10px",
            textAlign: "center",
            backgroundColor: "#eaeeef",
          }}
        >
          <label>LINEアカウントを連携して役立つ情報をお知らせします</label>
          <TextField
            id="email"
            label="メールアドレス"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            style={{ marginBottom: "0px", marginTop: "20px" }}
            placeholder="taro@witc.co.jp"
            autoComplete="off"
            sx={{
              backgroundColor: "#ffffff",
            }}
          />
          {validationError && <p style={{ color: "red" }}>{validationError}</p>}
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Button
            style={{ marginBottom: "0px", marginTop: "40px" }}
            variant="contained"
            onClick={moveLineConnect}
            disabled={validationError !== null}
          >
            次に進む
          </Button>
        </Box>
      </Container>
    </>
  );
};
export default InputEmail;
