import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <img
        src="./images/logo.svg"
        alt="WORLD INTEC"
        style={{ width: 280, padding: 10 }}
      />
    </>
  );
};
export default Header;
