import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Head from "../components/Head";
import { TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ComfirmEmail = () => {
  const location = useLocation();
  const [email, setEmail] = useState<string>(location.state);
  const navigate = useNavigate();

  useEffect(() => {
    if (email == null) {
      navigate("/");
    }
  }, []);

  const connectLine = () => {
    console.log("ここにLINE ID連携を書く");
  };

  return (
    <>
      <Head title="LINE ID連携" description="LINE IDを連携します" />
      <Container
        maxWidth="sm"
        style={{
          marginTop: "20px",
          border: "1px solid #ccc",
          padding: "20px",
          height: "250px",
        }}
      >
        <Box
          sx={{
            bgcolor: "#ffffff",
            height: "150px",
            textAlign: "center",
            backgroundColor: "#eaeeef",
          }}
        >
          <label>ボタンをクリックするとLINEアプリの連携画面に移ります</label>
          <Box sx={{ marginTop: "30px", fontSize: "30px", fontWeight: "500" }}>
            <label htmlFor="email">{email}</label>
          </Box>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Button
            style={{
              marginBottom: "0px",
              marginTop: "20px",
              marginRight: "20px",
            }}
            variant="outlined"
            onClick={() => navigate("/")}
          >
            戻る
          </Button>
          <Button
            style={{ marginBottom: "0px", marginTop: "20px" }}
            variant="contained"
            onClick={connectLine}
          >
            LINEID連携する
          </Button>
        </Box>
      </Container>
    </>
  );
};
export default ComfirmEmail;
